import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import {
  LockOutlined,
  EditOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import { USER } from 'redux/constants/Auth';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import './css/NavProfile.css'
import GetSessionInfoModal from "./GetSessionInfoModal";

export const NavProfile = ({ signOut }) => {
  const getSessionInfoModalRef = useRef(null);
  const userStr = localStorage.getItem(USER);
  let user;
  if (userStr) {
    user = JSON.parse(userStr);
  }

  const userDisplayName = user?.firstName ? `${user.firstName} ${user.lastName}` : (user?.email ? user?.email : 'Unknown User');
  const showEditProfile = user && user.userType !== "ssoUser";

  const handleOpenGetSessionInfoModalRef = () => {
    getSessionInfoModalRef.current.handleOpenModal();
  };

  const profileMenu = (
    <div className="nav-profile nav-dropdown mt-3">
      <div className="nav-profile-header">
        <div className="signoutName">
          {/* <Avatar size={45} src={profileImg} /> */}
          <div className="text-center w-100" title={userDisplayName}>
            {/* {user?.firstName && <h4 className="mb-0">{user.firstName} {user.lastName}</h4>}
            {!user?.firstName && user?.email && <h4 className="mb-0">{user.email}</h4>} */}
            {/* <span className="text-muted">Frontend Developer</span> */}
            <h5 className="mb-0" style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>{userDisplayName}</h5>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        {/* settings selectedKeys to empty array will cause the menu item to be highligted only and not selected */}
        <Menu selectedKeys={[]}>
          <Menu.Item key="getSessionInfo" onClick={() => handleOpenGetSessionInfoModalRef()}>
            <Icon type={LockOutlined} />
            <span className="font-weight-normal">Get Session Information</span>
          </Menu.Item>
          {showEditProfile && (<Menu.Item key="editProfile">
            <Icon type={EditOutlined} />
            <span className="font-weight-normal">Edit Profile</span>
            <Link to={`${APP_PREFIX_PATH}/user/setting`} />
          </Menu.Item>)}
          <Menu.Item key="signOut" onClick={e => signOut()}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <>
      <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]} className="ml-0">
        <Avatar
          gap={1}
          style={{
            backgroundColor: '#7265e6',
            cursor: "pointer"
          }}
        >
          {user?.firstName ? user.firstName.charAt(0).toUpperCase() : (user?.email ? user.email.charAt(0).toUpperCase() : 'A')}
        </Avatar>
      </Dropdown>
      <GetSessionInfoModal ref={getSessionInfoModalRef} user={user} />
    </>
  );
}

export default connect(null, { signOut })(NavProfile)
