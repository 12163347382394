import React, { useState, forwardRef, useImperativeHandle, useContext } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Row, Col, Descriptions } from "antd";
import TooltipDrawer from "components/shared-components/TooltipDrawer/TooltipDrawer";
import AvatarStatus from "components/shared-components/AvatarStatus";
import AuthDataContext from "context/AuthDataContext";
import { GET_SESSION_INFO_MODAL } from "constants/TooltipConstant";
import { ROLE } from "constants/AppConstant";
import { TEAM_VIEW_PATH } from "configs/AppConfig";

const GetSessionInfoModal = forwardRef((props, ref) => {
    // on success callback function will be called on success of invite
    const { onSuccessCallback = () => {}, user } = props;
    const { firstName, lastName, email } = user;
    const { orgUserRole, orgUserTeams } = useContext(AuthDataContext);
    const fullName = firstName && lastName ? `${firstName} ${lastName}` : "";

    const roles = Object.values(ROLE);
    const roleLabel = roles.find((r) => r.key === orgUserRole)?.label;
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = (data = {}) => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    // apply forwardRef child function to open the modal
    useImperativeHandle(ref, () => ({
        handleOpenModal,
    }));

    let name; // avatar title
    let subTitle; // avatar sub title (Description)
    let text; // text iside the avatars

    if (fullName) {
        name = fullName;
        subTitle = email;
        text = fullName.charAt(0).toUpperCase();
    } else {
        name = email;
        text = email.charAt(0).toUpperCase();
    }

    return (
        <Modal
            title={
                <>
                    Session Information <TooltipDrawer data={GET_SESSION_INFO_MODAL} />
                </>
            }
            visible={showModal}
            onCancel={handleCloseModal}
            footer={[
                <Button key="close" onClick={handleCloseModal}>
                    Close
                </Button>,
            ]}>
            <Row justify="center">
                <Col>
                    <AvatarStatus name={name} subTitle={subTitle} text={text} />
                </Col>
            </Row>
            <Row justify="center" className="mt-4 ml-2">
                <Col span={16}>
                    <Descriptions size="small" bordered column={1}>
                        {/* don't change the width to less then 85px because when it is less on pages like dashboard it does not effect but on control policies it is breaking the label */}
                        <Descriptions.Item labelStyle={{ width: "85px" }} label="Role">
                            {roleLabel}
                        </Descriptions.Item>
                        {orgUserTeams.length > 0 && (
                            <Descriptions.Item label="Teams">
                                {orgUserTeams.map((team, idx) => (
                                    <div>
                                        <Link to={`${TEAM_VIEW_PATH}/${team.id}`} onClick={handleCloseModal}>
                                            <Button className="p-0" type="link" key={idx}>
                                                {team.name}
                                            </Button>
                                        </Link>
                                    </div>
                                ))}
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                </Col>
            </Row>
        </Modal>
    );
});

export default GetSessionInfoModal;
