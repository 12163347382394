import {
  DashboardOutlined,
  GoldOutlined,
  SearchOutlined,
  ControlOutlined,
  PartitionOutlined,
  DisconnectOutlined,
  SnippetsOutlined,
  AuditOutlined,
  LockOutlined,
  ExceptionOutlined,
  HistoryOutlined,
  ContainerOutlined,
  SecurityScanOutlined
} from '@ant-design/icons';
import { ROUTES } from './AppRoutesConfig';

// To match sub paths substrings in menu highlight of current location we have to remove path params ids
const removeRoutePathParam = (route) => route.split('/:')[0];

// Note: subPaths are used for menu highlight only
const dashBoardNavTree = [{
  key: 'dashboard',
  path: ROUTES.DASHBOARD,
  title: 'dashboard',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [],
  subPaths: [
    ROUTES.AWS_DASHBOARD,
    ROUTES.AZURE_DASHBOARD
  ]
},
{
  key: 'iacStacks',
  path: ROUTES.NAMESPACES,
  title: 'iacStacks',
  icon: ControlOutlined,
  breadcrumb: false,
  submenu: [],
  subPaths: [
    removeRoutePathParam(ROUTES.NAMESPACE),
    removeRoutePathParam(ROUTES.STACK),
    removeRoutePathParam(ROUTES.DEPLOYMENT),
    removeRoutePathParam(ROUTES.PLAN),
    removeRoutePathParam(ROUTES.TASK),
    ROUTES.CREATE_NAMESPACE_WIZARD,
    removeRoutePathParam(ROUTES.EDIT_NAMESPACE_WIZARD),
    ROUTES.CREATE_STACK_WIZARD,
    removeRoutePathParam(ROUTES.EDIT_STACK_WIZARD),
    removeRoutePathParam(ROUTES.CREATE_STACK_FROM_TEMPLATE_WIZARD),
    removeRoutePathParam(ROUTES.CREATE_STACK_FROM_BLUEPRINT_WIZARD)
  ]
},
{
  key: 'activeRuns',
  path: ROUTES.ACTIVE_RUNS,
  title: 'activeRuns',
  icon: HistoryOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'templates',
  path: ROUTES.TEMPLATES,
  title: 'templates',
  icon: SnippetsOutlined,
  breadcrumb: false,
  submenu: [],
  subPaths: [
    removeRoutePathParam(ROUTES.TEMPLATE),
    ROUTES.CREATE_TEMPLATE_WIZARD,
    ROUTES.CREATE_BLUEPRINT_WIZARD,
    removeRoutePathParam(ROUTES.EDIT_BLUEPRINT_WIZARD),
    removeRoutePathParam(ROUTES.EDIT_TEMPLATE_WIZARD)
  ]
},
{
  key: 'controlPolicies',
  path: ROUTES.CONTROL_POLICIES,
  title: 'controlPolicies',
  icon: LockOutlined,
  breadcrumb: false,
  submenu: [],
  subPaths: [
    ROUTES.CREATE_CONTROL_POLICY_WIZARD,
    removeRoutePathParam(ROUTES.EDIT_CONTROL_POLICY_WIZARD),
    removeRoutePathParam(ROUTES.SECURITY_STANDARD)
  ]
},
{
  key: 'policyViolations',
  path: ROUTES.POLICY_VIOLATIONS,
  title: 'policyViolations',
  icon: SecurityScanOutlined,
  breadcrumb: false,
  submenu: [],
  subPaths: []
},
{
  key: 'driftCenter',
  path: ROUTES.DRIFT_CENTER,
  title: 'driftCenter',
  icon: DisconnectOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'resourceFinder',
  path: ROUTES.RESOURCE_FINDER,
  title: 'resourceFinder',
  icon: SearchOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'terraformImport',
  path: ROUTES.IAC_IMPORT,
  title: 'terraformImport',
  icon: GoldOutlined,
  breadcrumb: false,
  submenu: [],
  subPaths: [
    ROUTES.CREATE_MODEL_WIZARD,
    removeRoutePathParam(ROUTES.IMPORT_STACK_WIZARD),
    removeRoutePathParam(ROUTES.IMPORT_TO_EXISTING_STACK_WIZARD),
  ]
},
{
  key: 'tfExplorer',
  path: ROUTES.TF_EXPLORER,
  title: 'tfExplorer',
  icon: PartitionOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'cloudEvents',
  path: ROUTES.CLOUD_EVENTS,
  title: 'cloudEvents',
  icon: ExceptionOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'reports',
  path: ROUTES.REPORTS,
  title: 'reports',
  icon: ContainerOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'audit',
  path: ROUTES.AUDIT,
  title: 'audit',
  icon: AuditOutlined,
  breadcrumb: false,
  submenu: []
}]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
