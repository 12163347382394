import React, { useState } from 'react';
import { Typography, Drawer, Divider } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import consoleBackendService from "services/ConsoleBackendService";
import { GITBOOK_DOCS_URL } from 'constants/AppConstant';

export const handleDocsLinkClick = (e, docsUrl) => {
    e.preventDefault();
    consoleBackendService
        .createGitbookToken()
        .then((res) => {
            // extract location and anchor link (hash) from the docs url
            const url = new URL(docsUrl);
            const anchorLink = url.hash;
            const location = url.pathname;
            const gitbookToken = res.response.items[0].token;
            const openUrl = `https://${GITBOOK_DOCS_URL}${location}?jwt_token=${gitbookToken}${anchorLink ? anchorLink : ''}`;
            window.open(openUrl);
        })
        .catch(function (err) {
            // TODO - handle error.
            console.log("Error", err);
            
            // on any error open just the url
            window.open(docsUrl);
        });
}

const TooltipDrawer = (props) => {
    const { data, children } = props;
    const { btnText, title, description, learnMoreLinks, isIcon } = data;
    const { Text } = Typography;
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    return (
        <span onClick={(e) => { e.stopPropagation() }}>
            {isIcon && <span onClick={showDrawer}>{children}<InfoCircleOutlined  style={{ marginRight: 4, color: '#3e79f7', cursor: 'pointer' }} /></span>}
            {!isIcon && <Text type="text" onClick={showDrawer} style={{ fontSize: 12, fontWeight: 600, marginLeft: 10, cursor: 'pointer', display: 'inline-block', color: '#3e79f7' }}>{btnText ?? 'Info'}</Text>}
            <Drawer style={{ zIndex: 1031 }} getContainer={true} visible={open} title={title} placement="right" onClose={onClose} open={open} maskStyle={{ backgroundColor: 'transparent' }}>
                <div style={{ whiteSpace: 'break-spaces', color: '#545b64', lineHeight: '1.8' }}>{description}</div>
                {learnMoreLinks?.length > 0 && <div>
                    <Divider />
                    <p style={{ color: '#1a3354', fontSize: '15px', fontWeight: 500, marginBottom: '4px' }}>Learn More</p>
                    {learnMoreLinks.map((link, key) => {
                        if (link.url.toLowerCase().includes(GITBOOK_DOCS_URL)) {
                            return <a onClick={(e) => handleDocsLinkClick(e, link.url)} style={{ display: 'block' }} href={link.url} target="_blank" rel="noopener noreferrer" key={key}>{link.text}</a>;
                        }

                        return <a style={{ display: 'block' }} href={link.url} target="_blank" rel="noopener noreferrer" key={key}>{link.text}</a>;
                    })}
                </div>}
            </Drawer>
        </span>
    );
};
export default TooltipDrawer;